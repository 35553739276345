<script>
import { required, email } from "vuelidate/lib/validators";
import appConfig from "@/app.config";

import axios from '@/modules/Axios'

/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      token: '',
      two_factor: '',
      resend: 0,
      modal: false,
      languages: [
        {
          flag: require("@/assets/images/flags/russia.jpg"),
          language: "ru",
          title: "Русский",
        },
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
      ],
      current_language: this.$i18n.locale,
      text: null,
      flag: null,
      value: null,
      loading: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  mounted() {
    document.body.classList.add("authentication-bg");
    this.value = this.languages.find((x) => x.language === this.$i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
  },
  methods: {
    async signIn () {
      try {
        this.loading = true
        let response
        if (this.resend) {
          response = await axios.post('Authentication.php', {
            action: 'signin',
            email: this.email,
            password: this.password,
            resend: 1
          })
        } else {
          response = await axios.post('Authentication.php', {
            action: 'signin',
            email: this.email,
            password: this.password,
            token: this.token
          })
        }

        this.loading = false
        if (response.data.status === 'token') {
          this.notify({
            title: this.$t('authentication.authorization'),
            type: 'warn',
            message: this.$t('authentication.warn_message_code_' + response.data.two_factor)
          })
          this.two_factor = response.data.two_factor
          this.modal = true
        } else {
          this.$store.commit('session/SET', {
            uid: response.data.session.uid,
            email: response.data.session.email,
            password: response.data.session.password
          })
          if (this.$store.state.authentication.redirect && this.$store.state.authentication.redirect !== '') {
            this.$router.push(this.$store.state.authentication.redirect)
            this.$store.commit('authentication/SET_REDIRECT', '')
          } else {
            this.$router.push('/client/userproducts')
          }
        }
      } catch (error) {
        this.notify({
          title: this.$t('authentication.authorization'),
          type: 'error',
          message: error.response ? this.$t(error.response.data) : error.message
        })
        this.loading = false
      }
    },
    setLanguage(locale, country, flag) {
      this.$i18n.locale = locale;
      this.current_language = locale;
      this.text = country;
      this.flag = flag;
      this.$store.commit('language/SET', locale)
    },
    // Try to log the user in with the username
    // and password they provided.
    /*tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          this.tryingToLogIn = true;
          // Reset the authError if it existed.
          this.authError = null;
          return (
            this.$store
              .dispatch("auth/logIn", {
                email: this.email,
                password: this.password,
              })
              // eslint-disable-next-line no-unused-vars
              .then((token) => {
                this.tryingToLogIn = false;
                this.isAuthError = false;
                // Redirect to the originally requested page, or to the home page
                this.$router.push(
                  this.$route.query.redirectFrom || {
                    path: "/",
                  }
                );
              })
              .catch((error) => {
                this.tryingToLogIn = false;
                this.authError = error ? error : "";
                this.isAuthError = true;
              })
          );
        } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
          const { email, password } = this;
          if (email && password) {
            this.$store.dispatch("authfack/login", {
              email,
              password,
            });
          }
        }
      }
    },*/
  },
  notifications: {notify: {}}
};
</script>

<template>
  <div>
    <div class="account-pages my-3 pt-sm-3 d-flex align-items-center justify-content-center w-100 h-100 position-fixed">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="text-center">
              <router-link to="/" class="mb-5 d-block auth-logo">
                <img
                  src="@/assets/images/logo_adman.png"
                  alt
                  class="logo logo-dark mw-100"
                />
                <img
                  src="@/assets/images/logo_adman.png"
                  alt
                  class="logo logo-light mw-100"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">{{ $t("authentication.control_panel_login") }}</h5>
                </div>
                <div class="p-2 mt-4">
                  <!--<b-alert
                    v-model="isAuthError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ authError }}</b-alert
                  >

                  <div
                    v-if="notification.message"
                    :class="'alert ' + notification.type"
                  >
                    {{ notification.message }}
                  </div>-->

                  <b-form @submit.prevent>
                    <b-form-group
                      id="input-group-1"
                      class="mb-3"
                      :label="$t('authentication.email_or_login')"
                      label-for="input-1"
                    >
                      <b-form-input
                        id="input-1"
                        v-model="email"
                        type="email"
                        autocomplete="email"
                        :class="{ 'is-invalid': submitted && $v.email.$error }"
                      ></b-form-input>
                      <!--<div
                        v-if="submitted && $v.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.email.required"
                          >Email is required.</span
                        >
                        <span v-if="!$v.email.email"
                          >Please enter valid email.</span
                        >
                      </div>-->
                    </b-form-group>

                    <b-form-group id="input-group-2" class="mb-3">
                      <label for="input-2">{{ $t("authentication.password") }}</label>
                      <b-form-input
                        id="input-2"
                        v-model="password"
                        type="password"
                        autocomplete="current-password"
                        :class="{
                          'is-invalid': submitted && $v.password.$error,
                        }"
                      ></b-form-input>
                      <!--<div
                        v-if="submitted && !$v.password.required"
                        class="invalid-feedback"
                      >
                        Password is required.
                      </div>-->
                    </b-form-group>
   <!--                 <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="auth-remember-check"
                      />
                      <label class="form-check-label" for="auth-remember-check"
                        >Remember me</label
                      >
                    </div>-->

                    <div class="mt-3 row">
                      <div class="col-sm-12 col-md-6">
                        <b-dropdown variant="white" right>
                          <template v-slot:button-content>
                            <img class="me-1" :src="flag" height="16" />
                            <span class="align-middle">{{ text }}<i class="uil-angle-down d-none d-xl-inline-block font-size-15"></i></span>
                          </template>
                          <b-dropdown-item
                            class="notify-item"
                            v-for="(entry, i) in languages"
                            :key="`Lang${i}`"
                            :value="entry"
                            @click="setLanguage(entry.language, entry.title, entry.flag)"
                            :link-class="{ active: entry.language === current_language }"
                          >
                            <img
                              :src="`${entry.flag}`"
                              alt="user-image"
                              class="me-1"
                              height="12"
                            />
                            <span class="align-middle">{{ entry.title }}</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                      <div class="col-sm-12 col-md-6 text-end">
                        <b-button type="submit" variant="primary" class="w-sm" @click="resend = 1; signIn()"
                          >{{$t('authentication.sign_in')}}</b-button
                        >
                      </div>
                    </div>

                    <!--<div class="mt-4 text-center">
                      <div class="signin-other-title">
                        <h5 class="font-size-14 mb-3 title">{{$t('authentication.sign_in_with')}}</h5>
                      </div>

                      <ul class="list-inline">
                        <li class="list-inline-item">
                          <a
                            href="javascript:void()"
                            class="social-list-item bg-primary text-white border-primary"
                          >
                            <i class="mdi mdi-facebook"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="javascript:void()"
                            class="social-list-item bg-info text-white border-info"
                          >
                            <i class="mdi mdi-twitter"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="javascript:void()"
                            class="social-list-item bg-danger text-white border-danger"
                          >
                            <i class="mdi mdi-google"></i>
                          </a>
                        </li>
                      </ul>
                    </div>-->
                  </b-form>
                </div>
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>
            <div class="mt-5 text-center">
              <p>
                {{ new Date().getFullYear() }} © Adman.com
              </p>
            </div>
            <!-- end row -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
    <b-modal v-model="modal" :title="$t('authentication.two_factor_authentication')">
      <div class="input-group mb-3">
        <div class="input-group-prepend input-group-text">
          <i class="fa fa-fw fa-unlock"></i>
        </div>
        <input class="form-control" :placeholder="$t('authentication.confirmation_code')" type="text" v-model="token">
      </div>
      <template v-slot:modal-footer>
        <button
          @click="resend = 0; signIn()" v-if="!loading"
          class="btn px-4 mx-1 btn-outline-success"
        >{{$t('authentication.sign_in')}}
        </button>
        <button v-else class="btn px-4 mx-1 btn-outline-success" disabled>
          <b-spinner small></b-spinner> {{$t('links.loading')}}...
        </button>
        <button v-show="two_factor != 'google'" v-if="!loading"
          @click="resend = 1; signIn()"
          class="btn btn-outline-primary px-4 mx-1"
        >{{$t('authentication.resend_confirmation_code')}}
        </button>
        <button v-else class="btn btn-outline-primary px-4 mx-1" disabled>
          <b-spinner small></b-spinner> {{$t('links.loading')}}...
        </button>
      </template>
    </b-modal>
  </div>
</template>

<style lang="scss" module></style>
